import axios from 'axios';
import { authHeader } from './auth-header';
import { API_URL_CATALOGO } from './constants';
import { API_URL_TRX } from './constants';
import { ERR_MSG_CONEXION } from './constants';

class InvService {
  getTrxs(AppCtx, filtro)
  {
    return axios.post(API_URL_TRX + '/gettrxs', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${JSON.stringify(filtro)}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  prepararBatchDocs(AppCtx, filtro)
  {
    return axios.post(API_URL_TRX + '/gettrxsbatchdocs', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${JSON.stringify(filtro)}`
    }, { headers: authHeader(), responseType: 'blob' }).then(response => {
      return response;
      }
    );
  }

  getInfoMovTipo(AppCtx, Tipo)
  {
    return axios.post(API_URL_TRX + '/getinfotipomovimiento', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`,
        Tipo: `${Tipo}`        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }
  
  getSeriesTipoMovimiento(AppCtx, Tipo)
  {
    return axios.post(API_URL_TRX + '/getseriestipomovimiento', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`,
        Tipo: `${Tipo}`        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getFormatoImpresionTrx(AppCtx, movimiento, referencia, notif)
  {    
    return axios.post(API_URL_TRX + '/getrpttrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${movimiento.id_movimiento}`,
        Referencia: `${referencia}`,
        Notificar: notif
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  getTrxFELXml(AppCtx, id)
  {    
    return axios.post(API_URL_TRX + '/gettrxfelxml', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${id}`
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarMovimiento(AppCtx, Movimiento)
  {
    let data = JSON.stringify(Movimiento);

    return axios.post(API_URL_TRX + '/registrartrx', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => 
      {
            return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getTrx(AppCtx, Id)
  {
    return axios.post(API_URL_TRX + '/gettrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getTrxDetalle(AppCtx, Id)
  {
    return axios.post(API_URL_TRX + '/gettrxdetalle', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getTrxAtributos(AppCtx, Id)
  {
    return axios.post(API_URL_TRX + '/gettrxatributos', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getTrxFELInfo(AppCtx, Id)
  {
    return axios.post(API_URL_TRX + '/gettrxfelinfo', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  GetTrxXContrato(AppCtx, Condiciones) {
      return axios.post(API_URL_TRX + '/gettrxxcontrato', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${JSON.stringify(Condiciones)}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  } 

  emitirTrxXID(AppCtx, Cargo) {
    
    return axios.post(API_URL_TRX + '/getemitirtrxxid', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${JSON.stringify(Cargo)}`
  }, { headers: authHeader() }).then(response => {
    response.data.id_movimiento_old = Cargo.id_movimiento;
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );
  } 

  anularMovimiento(AppCtx, movimiento, razon)
  {
    return axios.post(API_URL_TRX + '/anulartrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${movimiento.id_movimiento}`,
        Razon: `${razon}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  buscaProductos(AppCtx, filtro, mostrarConversiones, tipoProducto, idProductoLista, mostrarMateriaPrima)
  {
    return axios.post(API_URL_CATALOGO + '/buscaproductos', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${AppCtx.id_referencia}`,
      Filtro: `${filtro}`,
      MostrarConversion: `${mostrarConversiones}`,
      TipoProducto: `${tipoProducto}`,
      IdProductoLista: `${idProductoLista}`,
      MostrarMateriaPrima: `${mostrarMateriaPrima}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getListasPrecios(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/precioslista', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getListaPrecios(AppCtx, IdPrecioLista)
  {
    return axios.post(API_URL_CATALOGO + '/getlistaprecios', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${IdPrecioLista}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  registrarListaPrecio(AppCtx, Lista) {
    
    let data = JSON.stringify(Lista);

    return axios.post(API_URL_CATALOGO + '/registrarlistaprecio', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  registrarListaPrecioDet(AppCtx, ListaDetalle) {
    
    let data = JSON.stringify(ListaDetalle);

    return axios.post(API_URL_CATALOGO + '/registrarlistapreciodetalle', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  eliminarListaPrecio(AppCtx, lista)
  {
    let id_precio_lista = lista.id_precio_lista;
    
    return axios.post(API_URL_CATALOGO + '/removerlistaprecio', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_precio_lista}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarListaPrecioDetalle(AppCtx, ListaDetalle)
  {
    let id_precio_lista_det = ListaDetalle.id_precio_lista_det;
    
    return axios.post(API_URL_CATALOGO + '/Removerlistapreciodetalle', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_precio_lista_det}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getPrecioProducto(AppCtx, IdProductoEmpresa, IdCtaHabienteInfo, IdUnidadMedida)
  {
    return axios.post(API_URL_TRX + '/getprecioproducto', {
      AppCtx: `${AppCtx.codigo}`,
      IdProductoEmpresa: `${IdProductoEmpresa}`,
      IdCtaHabienteInfo: `${IdCtaHabienteInfo}`,
      IdUnidadMedida: `${IdUnidadMedida}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }
  
  getExistenciaProducto(AppCtx, IdProductoEmpresa)
  {
    return axios.post(API_URL_TRX + '/getproductoexistencia', {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${IdProductoEmpresa}`,
      param1: `${AppCtx.id_empleado}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  GetProductoKardex(AppCtx, Filtro){
    let data = JSON.stringify(Filtro);
    
    return axios.post(API_URL_TRX + '/getproductokardex', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  buscaMovsOrigen(AppCtx, filtro)
  {
    return axios.post(API_URL_TRX + '/getmovimientosporaplicar', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${JSON.stringify(filtro)}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  buscaMovsAnulados(AppCtx, filtro)
  {
    return axios.post(API_URL_TRX + '/getmovimientosanulados', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${JSON.stringify(filtro)}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getTiposProducto(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productostipos', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getCategoriasProducto(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productoscategorias', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  registrarInvCategoria(AppCtx, Categoria) {
    
    let data = JSON.stringify(Categoria);

    return axios.post(API_URL_CATALOGO + '/registrarproductocategoria', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  InvCategoriaEnUso(AppCtx, Categoria)
  {
    let id_categoria = Categoria.id_categoria;
    
    return axios.post(API_URL_CATALOGO + '/productocategoriaenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_categoria}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarInvCategoria(AppCtx, Categoria)
  {
    let id_categoria = Categoria.id_categoria;
    
    return axios.post(API_URL_CATALOGO + '/removerproductocategoria', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_categoria}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getUnidadesMedida(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/unidadesmedida', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  registrarUnidadMedida(AppCtx, UnidadMedida) {
    
    let data = JSON.stringify(UnidadMedida);

    return axios.post(API_URL_CATALOGO + '/registrarunidadmedida', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  UnidadMedidaEnUso(AppCtx, UnidadMedida)
  {
    let id_unidad_medida = UnidadMedida.id_unidad_medida;
    
    return axios.post(API_URL_CATALOGO + '/unidadmedidaenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_unidad_medida}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarUnidadMedida(AppCtx, UnidadMedida)
  {
    let id_unidad_medida = UnidadMedida.id_unidad_medida;
    
    return axios.post(API_URL_CATALOGO + '/removerunidadmedida', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_unidad_medida}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getMetodosCosteo(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productosmetodoscosteo', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getClasificaciones(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productosclasificacion', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  registrarInvClasificacion(AppCtx, Clasificacion) {
    
    let data = JSON.stringify(Clasificacion);

    return axios.post(API_URL_CATALOGO + '/registrarproductoclasificacion', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  InvClasificacionEnUso(AppCtx, Clasificacion)
  {
    let id_clasificacion = Clasificacion.id_clasificacion;
    
    return axios.post(API_URL_CATALOGO + '/productoclasificacionenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_clasificacion}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarInvClasificacion(AppCtx, Clasificacion)
  {
    let id_clasificacion = Clasificacion.id_clasificacion;
    
    return axios.post(API_URL_CATALOGO + '/removerproductoclasificacion', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_clasificacion}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getSubclasificaciones(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productossubclasificacion', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  registrarInvSubclasificacion(AppCtx, Subclasificacion) {
    
    let data = JSON.stringify(Subclasificacion);

    return axios.post(API_URL_CATALOGO + '/registrarproductosubclasificacion', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  InvSubclasificacionEnUso(AppCtx, Subclasificacion)
  {
    let id_subclasificacion = Subclasificacion.id_subclasificacion;
    
    return axios.post(API_URL_CATALOGO + '/productoclasificacionenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_subclasificacion}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarInvSubclasificacion(AppCtx, Subclasificacion)
  {
    let id_subclasificacion = Subclasificacion.id_subclasificacion;
    
    return axios.post(API_URL_CATALOGO + '/removerproductosubclasificacion', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_subclasificacion}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getProductosMarcas(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productosmarcas', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  registrarInvMarca(AppCtx, Marca) {
    
    let data = JSON.stringify(Marca);

    return axios.post(API_URL_CATALOGO + '/registrarproductomarca', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  InvMarcaEnUso(AppCtx, Marca)
  {
    let id_marca = Marca.id_marca;
    
    return axios.post(API_URL_CATALOGO + '/productomarcaenuso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_marca}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarInvMarca(AppCtx, Marca)
  {
    let id_marca = Marca.id_marca;
    
    return axios.post(API_URL_CATALOGO + '/removerproductomarca', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_marca}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getAlmacenamientoTipos(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/productosalmacenamientotipos', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getProductos(AppCtx)
  {
      return axios.post(API_URL_CATALOGO + '/productos', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.id_referencia}`,
        Filtro: "",
        MostrarConversion: "N",
        TipoProducto: "",
        IdLista: "",
        MostrarMateriaPrima: "N"
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  ExisteCodigoProducto(AppCtx, codigo) {
      return axios.post(API_URL_CATALOGO + '/codigoexistente', {
        AppCtx: `${AppCtx.codigo}`,
        Codigo: `${codigo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  registrarProducto(AppCtx, producto)
  {
    let data = JSON.stringify(producto);
    return axios.post(API_URL_CATALOGO + '/registrarproducto', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `[${data}]`},
      { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );   
  }

  getKpis(AppCtx, Index)
  {
    return axios.post(API_URL_TRX + '/getkpis', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${AppCtx.id_referencia}`,
        Index: Index
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  eliminarProducto(AppCtx, producto)
  {
    let id_producto_empresa = producto.id_producto_empresa;
    
    return axios.post(API_URL_CATALOGO + '/eliminarproducto', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_producto_empresa}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getPlantillasMov(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/plantillasmov', {
      AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getPlantillaMov(AppCtx, IdPlantilla)
  {
    return axios.post(API_URL_CATALOGO + '/getplantillamov', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${IdPlantilla}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  async registrarPlantillaMov(AppCtx, Lista) {
    
    let data = JSON.stringify(Lista);

    return axios.post(API_URL_CATALOGO + '/registrarplantillamov', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  registrarPlantillaMovDet(AppCtx, PlantillaDetalle) {
    
    let data = JSON.stringify(PlantillaDetalle);

    return axios.post(API_URL_CATALOGO + '/registrarplantillamovdet', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  eliminarPlantillaMov(AppCtx, plantilla)
  {
    let id_mov_plantilla = plantilla.id_mov_plantilla;
    
    return axios.post(API_URL_CATALOGO + '/removerplantillamov', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_mov_plantilla}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  eliminarPlantillaMovDet(AppCtx, PlantillaDetalle)
  {
    let id_mov_plantilla_det = PlantillaDetalle.id_mov_plantilla_det;
    
    return axios.post(API_URL_CATALOGO + '/removerplantillamovdet', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_mov_plantilla_det}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }
        
      }
    )           
  }

  getPlantillasNotificacion(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/plantillasnotif', {
      AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }  
  
  getCtaHabienteEmailsNotificacion(AppCtx, IdCtaHabienteInfo)
  {
      return axios.post(API_URL_CATALOGO + '/ctahabienteemailsnotificacion', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdCtaHabienteInfo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  enviarEmailTrx(AppCtx, Notif)
  {    
    return axios.post(API_URL_TRX + '/enviarnotificacionestrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Notif.id}`,
        Data: `${JSON.stringify(Notif.emails)}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  
}

export default  new InvService();
